import { useParams } from "react-router-dom";

// components
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { Changelog } from "./Changelog/Changelog";
import { Comments } from "./Comments/Comments";
import { SubmissionGeneraInfo } from "./SubmissionGeneraInfo/SubmissionGeneraInfo";
import { FormIFrame } from "../FormIFrame/FormIFrame";

// hooks
import { useSubmission } from "./useSubmission";
import { useForm } from "../../hooks/queries/useForm";

// helpers
import { routes } from "../../routes/routes";
import { join } from "../../utils/join";

// styles
import s from "./Submission.module.scss";

export const Submission = () => {
  const { formId, processNo } = useParams();
  const { data: submission } = useSubmission(processNo);
  const { data: form } = useForm(formId);
  const { title } = form?.options || {};

  return (
    <>
      <Breadcrumb
        className="container"
        items={[
          {
            path: `/${routes.pathToForms()}`,
            label: "Formulare"
          },
          {
            path: `/${routes.pathToSubmissions(formId!)}`,
            label: title!
          },
          {
            path: `/${routes.pathToSubmission(formId!, processNo!)}`,
            label: processNo!
          }
        ]}
      />
      <main className={join([s["submission"], "container"])}>
        <div className={join([s["submission--main-content"]])}>
          <SubmissionGeneraInfo
            className={s["submission--info"]}
            formTitle={title}
            formId={formId}
            processNo={processNo}
            status={submission?.status}
            reason={submission?.statusUpdateComment}
          />
          <Comments className={s["submission--comments"]} comments={submission?.comments} />
          <Changelog className={s["submission--changelog"]} logs={submission?.changeLog} />
        </div>
        <FormIFrame className={s["submission--form"]} props={submission?.props} />
      </main>
    </>
  );
};
