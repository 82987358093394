import { ReactNode } from "react";
import { Header } from "../../components/Header/Header";

interface IAdminLayout {
  children: ReactNode;
}

export const AdminLayout = ({ children }: IAdminLayout) => {
  return (
    <>
      <Header />
      {children}
    </>
  );
};
