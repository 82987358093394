import { QueryObserverOptions, useQuery } from "@tanstack/react-query";

import { api } from "../../api/api";

// types
import { IForm } from "../../types/forms.types";

const fetcher = async (formId?: string) => {
  const res = await api.get({ url: `/forms/${formId}` });

  return res;
};

export const useForm = <TQueryFnData = IForm, TData = IForm>(
  formId: string | undefined,
  options?: QueryObserverOptions<TQueryFnData, unknown, TData>
) => {
  return useQuery<TQueryFnData, unknown, TData>(["submission", formId], () => fetcher(formId), {
    ...options,
    staleTime: Infinity,
    enabled: !!formId
  });
};
