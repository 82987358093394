import { statuses } from "../../constants/constants";

// styles
import s from "./BulletStatus.module.scss";

interface IBulletStatus {
  label: string;
  status: string;
}

export const BulletStatus = ({ label, status }: IBulletStatus) => {
  const { color } = statuses[status] || { color: "blue" };

  return (
    <span className={s["bullet-status"]}>
      <span
        className={[s["bullet-status--bullet"], s[`bullet-status--bullet__${color}`]].join(" ")}
      />
      <span className="secondary">{label}</span>
    </span>
  );
};
