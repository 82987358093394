import { useLocation } from "react-router-dom";

// components
import { Link } from "../Link/Link";

// helpers
import { join } from "../../utils/join";

// styles
import s from "./Breadcrumb.module.scss";

interface IBreadcrumb {
  className?: string;
  items: Array<{ path: string; label: string }>;
}

export const Breadcrumb = ({ items, className }: IBreadcrumb) => {
  const { pathname } = useLocation();

  return (
    <nav className={className}>
      <ul className={s["breadcrumb"]}>
        {items.map((el, index) => {
          const isLast = index === items.length - 1;

          return (
            <li
              className={join([
                s["breadcrumb--item"],
                pathname === el.path && s["breadcrumb--item__active"]
              ])}
              key={el.path}
            >
              <Link to={el.path}>
                {el.label}
                {!isLast ? " /" : ""}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
