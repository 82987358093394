import { useState } from "react";
import { QueryClient } from "@tanstack/react-query";

export const useSetupQueryClient = () => {
  const [queryClient] = useState(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          onError: (err) => {
            // TODO: here is good place for handling 401 error
            console.error(`Query was failed with error: ${err}`);
          }
        }
      }
    });
  });

  return queryClient;
};
