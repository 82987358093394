import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// components
import { Button } from "../../components/Button/Button";
import { Input } from "../../components/Input/Input";
import { useSubmissionValidate } from "../../hooks/queries/useSubmissionValidate";

// helpers
import { routes } from "../../routes/routes";

// styles
import s from "./UserSignIn.module.scss";

export const UserSignIn = () => {
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const submissionValidate = useSubmissionValidate({
    onError: (err: any) => {
      setError(
        "Es ist etwas fehlgeschlagen. Bitte überprüfen Sie nochmals alle Angaben."
      );
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["submission", data.processNo, "validation"], data);
      navigate(routes.pathToSubmissionResult(data.formId, data.processNo));
    }
  });

  const handleFromChange = () => {
    if (error) setError("");
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);

    submissionValidate.mutate({
      firstname: formData.get("firstname") as string,
      lastname: formData.get("lastname") as string,
      email: formData.get("email") as string,
      submissionId: formData.get("code") as string
    });
  };

  return (
    <article className={s["user-sign-in"]}>
      <h4>Antragsergebnisse</h4>
      <form onChange={handleFromChange} onSubmit={onSubmit} className={s["user-sign-in--from"]}>
        <div className={s["user-sign-in--row"]}>
          <Input name="firstname" label="Vorname" placeholder="Vorname" required />
          <Input name="lastname" label="Nachname" required placeholder="Nachname" />
        </div>
        <Input name="email" label="Email" type="email" required placeholder="example@email.com" />
        <Input
          name="code"
          label="Ihre Auftragsnummer"
          required
          caption="Die Auftragsnummer wurde Ihnen per E-Mail zugesandt."
          placeholder="ABC-XXX-XXXXXX"
        />

        {error && <p className={s["user-sign-in--error"]}>{error}</p>}

        <Button
          disabled={submissionValidate.isLoading}
          aria-busy={submissionValidate.isLoading}
          className={s["user-sign-in--submit-btn"]}
          type="submit"
        >
          Login
        </Button>
      </form>
    </article>
  );
};
