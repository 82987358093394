import { statuses } from "../../constants/constants";

// styles
import s from "./StatusBadge.module.scss";

export interface IStatusBadge {
  status: string;
  label: string;
}

export const StatusBadge = ({ status, label }: IStatusBadge) => {
  const { color } = statuses[status] || { color: "blue" };

  return (
    <div className={[s["status-badge"], s[`status-badge__${color}`]].join(" ")}>
      <span>{label}</span>
    </div>
  );
};
