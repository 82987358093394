// components
import { SubmitCommentForm } from "./SubmitCommentFrom/SubmitCommentForm";
import { Empty } from "../../../components/Empty/Empty";

// helpers
import { formatForUIWitTime } from "../../../utils/dates";
import { join } from "../../../utils/join";

// types
import { IComment } from "../../../types/submission.types";

import s from "./Comments.module.scss";

interface ICommentsProps {
  className?: string;
  comments?: Array<IComment>;
}

export const Comments = ({ className, comments }: ICommentsProps) => {
  return (
    <article className={join([s["comments"], "pd-0", className])}>
      <header>
        <h4 className="mg-0">Interne Notizen</h4>
      </header>
      {comments?.length ? (
        <ul className={s["comments--list"]}>
          {comments?.map((comment) => (
            <li key={comment.id} className={s["comments--list-item"]}>
              <span className="fl-between ">
                <span>{comment.author}</span>
                <span className="secondary">{formatForUIWitTime(new Date(comment.time))}</span>
              </span>
              <span className="secondary">{comment.content}</span>
            </li>
          ))}
        </ul>
      ) : (
        <Empty className="mg-v-auto" />
      )}
      <SubmitCommentForm />
    </article>
  );
};
