import { ReactNode } from "react";

// components
import { Empty } from "../Empty/Empty";

// styles
import s from "./CardList.module.scss";

interface ICardList<T> {
  cards?: Array<T>;
  renderCard: (data: T) => ReactNode;
  processing?: boolean;
}

export const CardList = <T,>({ cards, renderCard, processing }: ICardList<T>) => {
  return (
    <div aria-busy={processing} className={s["card-list"]}>
      {!!cards?.length && cards.map(renderCard)}
      {!cards?.length && !processing && <Empty />}
    </div>
  );
};
