import { QueryObserverOptions, useQuery } from "@tanstack/react-query";

import { api } from "../../api/api";

// types
import { ISubmission } from "../../types/submission.types";

const fetcher = async (processNo?: string) => {
  const res = await api.get({ url: `/submissions/${processNo}` });

  return res;
};

export const useSubmission = <TQueryFnData = ISubmission, TData = ISubmission>(
  processNo: string | undefined,
  options?: QueryObserverOptions<TQueryFnData, unknown, TData>
) => {
  return useQuery<TQueryFnData, unknown, TData>(
    ["submission", processNo],
    () => fetcher(processNo),
    {
      ...options,
      enabled: !!processNo
    }
  );
};
