import { useState } from "react";
import { useParams } from "react-router-dom";

// components
import { Upload, TUploadedFile } from "../../../components/Upload/Upload";

// hooks
import { useSubmission } from "../useSubmission";
import { useUploadSubmissionDocument } from "./useUploadSubmissionDocument";
import { useDelSubmissionAttachment } from "./useDelSubmissionAttachment";

// types
import { ISubmission } from "../../../types/submission.types";

export const UploadSubmissionDocuments = () => {
  const { processNo } = useParams();

  const [uploads, setUploads] = useState<TUploadedFile[]>([]);

  useSubmission(processNo, {
    onSuccess: (data) => {
      setUploads(data.attachments.map((el) => ({ ...el, processing: false, status: "uploaded" })));
    }
  });

  const onSuccess = (data: ISubmission) => {
    if (data.attachments) {
      setUploads(() =>
        data.attachments.map((el) => ({
          ...el,
          processing: false,
          status: "uploaded"
        }))
      );
    }
  };

  const delSubmissionAttachment = useDelSubmissionAttachment({ onSuccess });

  const uploadSubmissionDocument = useUploadSubmissionDocument<ISubmission, unknown>({
    onSuccess,
    onError: () => {
      setUploads((prev) =>
        prev.map((el) =>
          el.processing
            ? {
                ...el,
                processing: false,
                status: "error",
                id: crypto.randomUUID()
              }
            : el
        )
      );
    }
  });

  const onUpload = (files: FileList) => {
    const formData = new FormData();
    const fileForUpdateQuery: TUploadedFile[] = [];

    for (const file of files) {
      formData.append("files[]", file, file.name);

      fileForUpdateQuery.push({
        name: (file as File).name,
        mimetype: (file as File).type,
        url: "",
        processing: true,
        id: (file as File & { id: string }).id
      });
    }

    setUploads((prev) => [...prev, ...fileForUpdateQuery]);

    if (processNo) {
      uploadSubmissionDocument.mutate({ submissionId: processNo, body: formData });
    }
  };

  const onRemove = (id: string) => {
    if (processNo) {
      setUploads((prev) => prev.map((el) => (el.id === id ? { ...el, processing: true } : el)));

      delSubmissionAttachment.mutate({ submissionId: processNo, attachmentId: id });
    }
  };

  return <Upload fileList={uploads} onUpload={onUpload} onRemove={onRemove} />;
};
