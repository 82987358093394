// components
import { Select } from "../Select/Select";
import { ReactComponent as BxSortDown } from "../../assets/icons/BxSortDown.svg";

export type TSortType = "default" | "updated_at" | "newest";

interface ISelectSortByDate {
  value: string;
  onSelect: (value: TSortType) => void;
}

export const SelectSortByDate = ({ value, onSelect }: ISelectSortByDate) => {
  return (
    <Select<TSortType>
      width={270}
      value={value}
      customLabel={(label) => `Sortieren nach: ${label}`}
      options={[
        { label: "Default", value: "default" },
        { label: "Zuletzt bearbeitet", value: "updated_at" },
        { label: "Zuletzt eingereicht", value: "newest" }
      ]}
      onSelect={onSelect}
      icon={<BxSortDown />}
    />
  );
};
