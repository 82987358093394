// helpers
import { join } from "../../utils/join";

// styles
import s from "./Input.module.scss";

interface IInput
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  maxRows?: number;
  autoIncreaseRows?: boolean;
  resize?: boolean;
  label?: string;
  caption?: string;
}

export const Input = ({ label, required, className, value, caption, ...props }: IInput) => {
  return label ? (
    <label>
      <span className={s.label}>
        {label} {required && <span className="red">*</span>}
      </span>
      <input
        className={join([s.input, className, s["input__with-label"]])}
        value={value}
        required={required}
        {...props}
      />
      <span className="fl-inline secondary mg-t-4">{caption}</span>
    </label>
  ) : (
    <>
      <input className={join([s.input, className])} value={value} required={required} {...props} />
      <span className="fl-inline secondary mg-t-4">{caption}</span>
    </>
  );
};
