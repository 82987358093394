import { ChangeEventHandler, FormEventHandler, useState } from "react";

// components
import { ReactComponent as Submit } from "../../../../assets/icons/Submit.svg";
import { Textarea } from "../../../../components/Textarea/Textarea";
import { Button } from "../../../../components/Button/Button";

// hooks
import { useAddComment } from "./useAddComment";

// styles
import s from "./SubmitCommentForm.module.scss";
import { useParams } from "react-router-dom";

export const SubmitCommentForm = () => {
  const { processNo } = useParams();

  const [comment, setComment] = useState("");
  const addComment = useAddComment();

  const onInput: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    addComment.mutate({ processNo: processNo!, content: comment });
  };

  return (
    <form className={s["submit-comment-form"]} onSubmit={handleSubmit}>
      <Textarea
        autoIncreaseRows
        className={s["submit-comment-form--textarea"]}
        value={comment}
        onChange={onInput}
        name="comment"
        rows={1}
        placeholder="Interne Notiz hinzufügen"
        // Interne Notiz hinzufügen
        disabled={addComment.isLoading}
      />
      <Button
        aria-busy={addComment.isLoading}
        className={s["submit-comment-form--btn"]}
        disabled={!comment}
        type="submit"
      >
        {!addComment.isLoading && <Submit />}
      </Button>
    </form>
  );
};
