import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { api } from "../../../api/api";

type TRequestOptions = { submissionId: string; body: FormData };

const fetcher = async ({ submissionId, body }: TRequestOptions) => {
  const resp = await api.post({
    url: `/submissions/${submissionId}/attachments`,
    body
  });

  return resp;
};

export const useUploadSubmissionDocument = <TData, TError>(
  options?: UseMutationOptions<TData, TError, TRequestOptions>
) => {
  return useMutation<TData, TError, TRequestOptions>(fetcher, options);
};
