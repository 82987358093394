import { Route, Routes, Navigate } from "react-router-dom";

import { AdminLayout } from "../layouts/AdminLayout/AdminLayout";
import { Forms } from "../containers/Forms/Forms";
import { Submissions } from "../containers/Submissions/Submissions";
import { Submission } from "../containers/Submission/Submission";

import { routes } from "./routes";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

export const AdminRoutes = () => {
  const {error, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, error])
  
  return (
    isAuthenticated && !isLoading ? 
    <AdminLayout>
      <Routes>
        <Route index element={<Navigate to={routes.FORMS} replace />} />
        <Route path={routes.FORMS} element={<Forms />} />
        <Route path={routes.FORM_SUBMISSIONS} element={<Submissions />} />
        <Route path={routes.FORM_SUBMISSION} element={<Submission />} />
      </Routes>
    </AdminLayout>
    : <div>Sie werden weitergeleitet...</div>
  );
};
