// components
import { Document } from "./Document";
import { ReactComponent as Trash } from "../../../assets/icons/Trash.svg";
import { join } from "../../../utils/join";
import { Img } from "../../Img/Img";

import s from "./UploadItem.module.scss";

interface IUploadItem {
  id: string;
  name: string;
  mimetype?: string;
  url?: string;
  processing?: boolean;
  status?: "uploaded" | "error";
  onRemove?: (name: string) => void;
}

export const UploadItem = ({
  name,
  mimetype,
  url,
  processing,
  onRemove,
  status,
  id
}: IUploadItem) => {
  const isImg = mimetype?.includes("image");

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    onRemove?.(id);
  };

  return (
    <a
      href={url}
      target="_blank"
      className={join([s["upload-item"], status === "error" && s["upload-item__error"]])}
      rel="noreferrer"
      download={name}
    >
      {onRemove && (
        <button onClick={handleRemove} className={s["upload-item--remove-btn"]}>
          <Trash />
        </button>
      )}
      <div aria-busy={processing} className={s["upload-item--preview"]}>
        {isImg && !processing && <Img src={url} />}
        {!isImg && !processing && <Document mimetype={mimetype} />}
      </div>
      <div className={s["upload-item--name"]}>
        <span>{name}</span>
      </div>
    </a>
  );
};
