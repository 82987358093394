// components
import { Link } from "../../components/Link/Link";
import { StatusBadge } from "../../components/StatusBadge/StatusBadge";
import { AttachmentsCount } from "../../components/AttachmentsCount/AttachmentsCount";

// helpers
import { routes } from "../../routes/routes";
import { combineUserName } from "../../utils/combineUserName";
import { join } from "../../utils/join";

// types
import { ISubmission } from "../../types/submission.types";

import s from "./SubmissionsCard.module.scss";
import { formatForUI } from "../../utils/dates";

interface ISubmissionsCard {
  submission: ISubmission & { statusName: string };
}

export const SubmissionsCard = ({ submission }: ISubmissionsCard) => {
  const { processNo, formId, attachments, props, createdAt, updatedAt } = submission;

  return (
    <article>
      <div className="fl-between divide">
        <div className="fl-col">
          <span className="uppercase">{processNo}</span>
          <div className="fl-gap-8 mg-t-8">
            <StatusBadge status={submission.status} label={submission.statusName} />
            <AttachmentsCount count={attachments.length} />
          </div>
        </div>

        <Link
          to={`/${routes.pathToSubmission(formId, processNo)}`}
          role="button"
          className="secondary outline w-auto h-fit-content"
        >
          Details anzeigen
        </Link>
      </div>

      <div className={join([s["submissions-card--footer"], "fl-between"])}>
        <div className={join([s["submissions-card--info"]])}>
          <span className="fl-gap-8">
            <span className="secondary">Firma</span>
            <span>{props.company}</span>
          </span>

          <span className="fl-gap-8">
            <span className="secondary">Full name</span>
            <span>{combineUserName(props)}</span>
          </span>
        </div>

        <div className={join([s["submissions-card--date"], "secondary", "fs-12"])}>
          <span>Eingereicht am: {formatForUI(new Date(createdAt))}</span>
          <span>Zuletzt bearbeitet: {formatForUI(new Date(updatedAt))}</span>
        </div>
      </div>
    </article>
  );
};
