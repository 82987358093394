import { v4 as uuidV4 } from "uuid";

// components
import { ReactComponent as CloudUpload } from "../../assets/icons/CloudUpload.svg";
import { UploadItem } from "./UploadItem/UploadItem";

// helpers
import { join } from "../../utils/join";

// style
import s from "./Upload.module.scss";

export type TUploadedFile = {
  mimetype: string;
  name: string;
  url: string;
  processing?: boolean;
  status?: "uploaded" | "error";
  id: string;
};

type TInput = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface IUploadControlled {
  fileList: TUploadedFile[];
  onUpload?: (file: FileList) => void;
  onRemove?: (val: string) => void;
  listClassName?: string;
}

export const Upload = ({
  multiple = true,
  fileList,
  onUpload,
  onRemove,
  listClassName
}: TInput & IUploadControlled) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0] as File & { id: string };

    if (!file) return;

    file.id = uuidV4();

    if (onUpload) {
      return e.target?.files && onUpload(e.target?.files);
    }
  };

  return (
    <div className={s["upload"]}>
      <ul className={join([listClassName, s["upload--list"]])}>
        {(fileList || []).map((el) => (
          <li key={el.id}>
            <UploadItem
              onRemove={onRemove}
              name={el.name}
              mimetype={el.mimetype}
              url={el.url}
              processing={el.processing}
              status={el.status}
              id={el.id}
            />
          </li>
        ))}
      </ul>
      {onUpload && (
        <label className={s["upload--drop-zone"]}>
          <CloudUpload />
          <span>Hier Dateien hochladen</span>
          <input type="file" multiple={multiple} onChange={onChange} />
        </label>
      )}
    </div>
  );
};
