import { ReactNode, useEffect, useRef } from "react";

// hooks
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { useEscHandler } from "../../hooks/useEscHandler";

import { ReactComponent as Close } from "../../assets/icons/Close.svg";

// style
import s from "./Modal.module.scss";
import { Button } from "../Button/Button";
import { join } from "../../utils/join";

// Get scrollbar width
const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll"; // forcing scrollbar to appear

  // outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement("div");
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode?.removeChild(outer);

  return scrollbarWidth;
};

// Is scrollbar visible
const isScrollbarVisible = () => {
  return document.body.scrollHeight > window.screen.height;
};

interface IModal {
  open: boolean;
  onClose: () => void;
  onOk?: () => void;
  title?: string;
  titleCaption?: string;
  children: ReactNode;
  width?: number;
  cancelBtnText?: string;
  okBtnText?: string;
  footerClassName?: string;
}

export const Modal = ({
  open,
  onClose,
  onOk,
  title,
  children,
  width = 414,
  titleCaption,
  cancelBtnText = "Abbrechen",
  okBtnText = "Ja, Status ändern",
  footerClassName
}: IModal) => {
  const ref = useRef<HTMLDialogElement>(null);
  const refContent = useRef<HTMLDialogElement>(null);

  // Config
  const isOpenClass = "modal-is-open";
  const openingClass = "modal-is-opening";
  const closingClass = "modal-is-closing";
  const animationDuration = 300; // ms

  useOutsideClick(refContent, onClose);
  useEscHandler(onClose);

  const handelOpenModal = () => {
    if (isScrollbarVisible()) {
      document.documentElement.style.setProperty("--scrollbar-width", `${getScrollbarWidth()}px`);
    }
    document.documentElement.classList.add(isOpenClass, openingClass);
    setTimeout(() => {
      document.documentElement.classList.remove(openingClass);
    }, animationDuration);
  };

  const handleCloseModal = () => {
    document.documentElement.classList.add(closingClass);
    setTimeout(() => {
      document.documentElement.classList.remove(closingClass, isOpenClass);
      document.documentElement.style.removeProperty("--scrollbar-width");
    }, animationDuration);
  };

  useEffect(() => {
    if (ref.current) {
      if (open) {
        handelOpenModal();
      } else {
        handleCloseModal();
      }
    }
  }, [open]);

  return (
    <dialog className={s["modal"]} open={open} ref={ref} id="modal-example">
      <article style={{ width }} className={s["modal--content"]} ref={refContent}>
        <button aria-label="Close" className={s["modal--close-btn"]} onClick={onClose}>
          <Close />
        </button>
        {(titleCaption || title) && (
          <header className={s["modal--header"]}>
            {title && <h3 className={s["modal--title"]}>{title}</h3>}
            {titleCaption && <p className={s["modal--title-caption"]}>{titleCaption}</p>}
          </header>
        )}
        <div>{children}</div>

        <footer className={join([s["modal--footer"], footerClassName])}>
          <Button
            className={join([
              s["modal--footer-btn"],
              s["modal--footer-btn__secondary"],
              "secondary"
            ])}
            onClick={onClose}
          >
            {cancelBtnText}
          </Button>
          <Button className={join([s["modal--footer-btn"]])} onClick={onOk}>
            {okBtnText}
          </Button>
        </footer>
      </article>
    </dialog>
  );
};
