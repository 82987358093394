import { ReactNode, useRef } from "react";

import s from "./Select.module.scss";

interface IFilter<T> {
  options: { label: string; value: T }[];
  onSelect: (value: T) => void;
  value?: string;
  icon?: ReactNode;
  customLabel?: (label?: string) => ReactNode;
  width?: number;
}

export const Select = <T extends string>({
  options,
  onSelect,
  value,
  icon,
  customLabel,
  width
}: IFilter<T>) => {
  const ref = useRef<any>(null);
  const selectedLabel = options.find((el) => el.value === value)?.label || value;

  const handleSelect = (value: T) => {
    ref.current.open = false;

    onSelect(value);
  };

  return (
    <details ref={ref} className={s.select} role="list">
      <summary style={{ width }} aria-haspopup="listbox" className="fl-gap-12" role="button">
        {icon}
        <span>{customLabel?.(selectedLabel) || selectedLabel}</span>
      </summary>
      <ul role="listbox">
        {options.map((option) => (
          <li
            className={[s["select--item"], value === option.value && s["select--item__selected"]]
              .filter(Boolean)
              .join(" ")}
            onClick={() => handleSelect(option.value)}
            key={option.value}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </details>
  );
};
