// components
import { useFormWorkflow } from "../../hooks/queries/useFromWorkflow";
import { Select } from "../Select/Select";

interface ISelectFilterByStatus {
  value: string;
  onSelect: (value: string) => void;
  formId: string;
}

export const SelectFilterByStatus = ({ value, onSelect, formId }: ISelectFilterByStatus) => {
  const { data: statuses } = useFormWorkflow(formId, {
    select: (statuses) => statuses.map((status) => ({ label: status.name, value: status.id }))
  });

  return (
    <Select
      width={237}
      value={value}
      customLabel={(label) => `Status: ${label}`}
      options={[{ label: "Alle", value: "all" }, ...(statuses || [])]}
      onSelect={onSelect}
    />
  );
};
