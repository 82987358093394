import { ISubmission } from "./../../../types/submission.types";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { api } from "../../../api/api";

interface IRequest {
  processNo: string;
  body: {
    newStatus: string;
    comment: string;
  };
}

const fetcher = async ({ processNo, body }: IRequest) => {
  return api.post({ url: `/submissions/${processNo}/status`, body });
};

export const useUpdateSubmissionStatus = <TData = ISubmission, TError = unknown>(
  options?: UseMutationOptions<TData, TError, IRequest>
) => {
  const queryClient = useQueryClient();

  return useMutation<TData, TError, IRequest>(fetcher, {
    ...options,
    onSuccess: (data, { processNo }) => {
      queryClient.setQueryData(["submission", processNo], data);
    }
  });
};
