// components
import { Empty } from "../../../components/Empty/Empty";

// helpers
import { IChangeLog } from "../../../types/submission.types";
import { formatForUIWitTime } from "../../../utils/dates";
import { join } from "../../../utils/join";

// styles
import s from "./Changelog.module.scss";

interface IChangelogProps {
  className?: string;
  logs?: Array<IChangeLog>;
}

export const Changelog = ({ className, logs }: IChangelogProps) => {
  return (
    <article className={join([s["changelog"], "pd-0", className])}>
      <header>
        <h4 className="mg-0">Änderungsprotokoll</h4>
      </header>

      {logs?.length ? (
        <div className={join([s["changelog--list-wrapper"]])}>
          <ul className={join([s["changelog--list"]])}>
            {logs?.map((el, index) => (
              <li key={`${index}`} className={join([s["changelog--list-item"]])}>
                <span className="fw-medium">{formatForUIWitTime(new Date(el.time))}</span>
                <span className="secondary">{el.content}</span>
                {el.comment && <span className="secondary">{el.comment}</span>}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <Empty className="mg-v-auto" />
      )}
    </article>
  );
};
