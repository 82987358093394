import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { api } from "../../api/api";

// types
import { ISubmission } from "../../types/submission.types";

const fetcher = async (formId?: string) => {
  return api.get({ url: `/forms/${formId}/submissions` });
};

export const useSubmissions = (formId?: string): UseQueryResult<ISubmission[], unknown> => {
  return useQuery<ISubmission[]>(["forms", formId, "submissions"], () => fetcher(formId), {
    enabled: !!formId
  });
};
