import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { api } from "../../../api/api";

type TRequestOptions = { submissionId: string; attachmentId: string };

const fetcher = async ({ submissionId, attachmentId }: TRequestOptions) => {
  const resp = await api.delete({
    url: `/submissions/${submissionId}/attachments/${attachmentId}`
  });

  return resp;
};

export const useDelSubmissionAttachment = <TData, TError>(
  options?: UseMutationOptions<TData, TError, TRequestOptions>
) => {
  return useMutation<TData, TError, TRequestOptions>(fetcher, options);
};
