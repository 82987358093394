import { useState } from "react";

// hooks
import { useForms } from "./useForms";

// components
import { Search } from "../../components/Search/Search";
import { FormItemCard } from "./FormItemCard";
import { CardList } from "../../components/CardList/CardList";

export const Forms = () => {
  const forms = useForms();
  const [search, setSearch] = useState("");

  const normalizedForms = search
    ? forms.data?.filter((form) =>
        form.options.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
    : forms.data;

  return (
    <main className="container">
      <nav>
        <Search value={search} onChange={(e) => setSearch(e.target.value)} />
      </nav>
      <CardList
        cards={normalizedForms}
        renderCard={(el) => <FormItemCard key={el.id} form={el} />}
        processing={forms.isLoading}
      />
    </main>
  );
};
