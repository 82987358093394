import s from "./Search.module.scss";

interface ISearch {
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Search = ({ placeholder = "Suche nach...", ...props }: ISearch) => {
  return <input type="search" className={s.search} placeholder={placeholder} {...props} />;
};
