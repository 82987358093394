import { ReactNode } from "react";

// style
// import s from "./CustomerLayout.module.scss";

interface ICustomerLayout {
  children: ReactNode;
}

export const CustomerLayout = ({ children }: ICustomerLayout) => {
  return (
    <>
      <main
      // className={s["customer-layout"]}
      >
        {children}
      </main>
    </>
  );
};
