class Routes {
  readonly ADMIN: string;
  readonly CUSTOMER: string;
  readonly FORMS: string;
  readonly SUBMISSIONS: string;
  readonly FORM_ID: string;
  readonly FORM_SUBMISSIONS: string;
  readonly FORM_SUBMISSION: string;
  readonly INFO: string;
  readonly PROCESS_NO: string;
  readonly SIGN_IN: string;
  readonly RESULT: string;
  readonly FORM_SUBMISSION_RESULT: string;

  constructor() {
    this.ADMIN = "admin";
    this.CUSTOMER = "customer";
    this.FORMS = "forms";
    this.SUBMISSIONS = "submissions";
    this.FORM_ID = ":formId";
    this.PROCESS_NO = ":processNo";
    this.INFO = "info";
    this.SIGN_IN = "sign-in";
    this.RESULT = "result";
    this.FORM_SUBMISSIONS = `${this.FORMS}/${this.FORM_ID}/${this.SUBMISSIONS}`;
    this.FORM_SUBMISSION = `${this.FORMS}/${this.FORM_ID}/${this.SUBMISSIONS}/${this.PROCESS_NO}/${this.INFO}`;
    this.FORM_SUBMISSION_RESULT = `${this.FORMS}/${this.FORM_ID}/${this.SUBMISSIONS}/${this.PROCESS_NO}/${this.RESULT}`;
  }

  pathToForms() {
    return [this.ADMIN, this.FORMS].join("/");
  }

  pathToSubmissions(formId: string) {
    return [this.ADMIN, this.FORMS, formId, this.SUBMISSIONS].join("/");
  }

  pathToSubmission(formId: string, submissionNumber: string) {
    return [this.ADMIN, this.FORMS, formId, this.SUBMISSIONS, submissionNumber, this.INFO].join(
      "/"
    );
  }

  pathToSubmissionResult(formId: string, submissionNumber: string) {
    return (
      "/" +
      [this.CUSTOMER, this.FORMS, formId, this.SUBMISSIONS, submissionNumber, this.RESULT].join("/")
    );
  }

  pathToSignIn() {
    return "/" + [this.CUSTOMER, this.SIGN_IN].join("/");
  }
}

export const routes = new Routes();
