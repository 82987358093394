import { QueryClientProvider } from "@tanstack/react-query";
import { Navigate, Route, Routes } from "react-router-dom";

import { AdminRoutes } from "./routes/AdminRoutes";
import { CustomerRoutes } from "./routes/CustomerRoutes";
import { routes } from "./routes/routes";
import { useSetupQueryClient } from "./hooks/useSetupQueryClient";

function App() {
  const queryClient = useSetupQueryClient();
 
  return (<QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<Navigate to={routes.ADMIN} replace />} />
        <Route path={`${routes.ADMIN}/*`} element={<AdminRoutes />} />
        <Route path={`${routes.CUSTOMER}/*`} element={<CustomerRoutes />} />
      </Routes>
    </QueryClientProvider>);
}

export default App;
