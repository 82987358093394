import { useAuth0 } from "@auth0/auth0-react";

interface IBaseParams {
  url: string;
  headers?: Record<string, string>;
}

interface IMutateParams extends IBaseParams {
  body: Record<string, unknown> | FormData | string;
}

class Api {

  baseApiUrl: string;
  headers: HeadersInit | undefined;

  constructor(baseApiUrl: string) {
    this.baseApiUrl = baseApiUrl;
    this.headers = { "Content-Type": "application/json" };
  }

  private fullUrl(url: string) {
    return `${this.baseApiUrl}/api${url}`;
  }

  private async handleResponse(res: Response) {
    const text = await res.text();
    const data = text && JSON.parse(text);

    if (!res.ok) {
      const error = (data && data.message) || res.statusText;
      return Promise.reject(error);
    }

    return data;
  }

  async get({ url }: IBaseParams) {
    const res = await fetch(this.fullUrl(url), { method: "GET" });

    return this.handleResponse(res);
  }

  async post({ url, body }: IMutateParams) {
    const isFormData = body instanceof FormData;
    const calculatedBody = isFormData ? body : JSON.stringify(body);

    const res = await fetch(this.fullUrl(url), {
      method: "POST",
      ...(!isFormData && { headers: this.headers }),
      body: calculatedBody
    });

    return this.handleResponse(res);
  }

  async put({ url, body }: IMutateParams) {
    const res = await fetch(this.fullUrl(url), {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(body)
    });

    return this.handleResponse(res);
  }

  async delete({ url }: IBaseParams) {
    const res = await fetch(this.fullUrl(url), { method: "DELETE" });

    return this.handleResponse(res);
  }
}

export const api = new Api(process.env.REACT_APP_API_URL || "");
