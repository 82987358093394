import { IForm } from "./../../types/forms.types";
import { useQuery } from "@tanstack/react-query";

import { api } from "../../api/api";

const fetcher = async () => {
  return api.get({ url: "/forms" });
};

export const useForms = () => {
  return useQuery<IForm[]>(["forms"], fetcher);
};
