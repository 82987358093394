// components
import { Link } from "../../components/Link/Link";
import { BulletStatus } from "../../components/BulletStatus/BulletStatus";

// helpers
import { routes } from "../../routes/routes";

// types
import { IForm } from "../../types/forms.types";

interface IFormItemCard {
  form: IForm;
  className?: string;
}

export const FormItemCard = ({ form, className }: IFormItemCard) => {
  const { title } = form.options;
  const total = form.workflow.reduce((sum, el) => sum + el.count, 0);

  return (
    <article className={className}>
      <div className="fl-between divide">
        <div className="fl-col fs-16">
          <span className="secondary">Formular</span>
          <span>{title}</span>
        </div>

        <Link
          to={`/${routes.pathToSubmissions(form.id)}`}
          role="button"
          className="secondary outline w-auto"
        >
          Aufträge ansehen
        </Link>
      </div>
      <div className="fl-gap-16 mg-t-14">
        <span className="secondary">
          <b>{total}</b> Aufträge:
        </span>
        {form.workflow.map((el) => (
          <BulletStatus key={el.id} status={el.id} label={`${el.count} ${el.name}`} />
        ))}
      </div>
    </article>
  );
};
