import { useEffect, useRef, useState } from "react";
import { join } from "../../utils/join";

// styles
import s from "./Textarea.module.scss";

interface ITextarea
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  maxRows?: number;
  autoIncreaseRows?: boolean;
  resize?: boolean;
  label?: string;
}

export const Textarea = ({
  value,
  rows,
  maxRows = 5,
  autoIncreaseRows,
  className,
  resize = true,
  label,
  required,
  ...props
}: ITextarea) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [internalRows, setInternalRows] = useState(rows || 1);

  useEffect(() => {
    if (ref.current && maxRows > internalRows && autoIncreaseRows) {
      if (ref.current.scrollHeight > ref.current.clientHeight) {
        setInternalRows((prev) => prev + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, maxRows, autoIncreaseRows]);

  return label ? (
    <label>
      <span className={s.label}>
        {label} {required && <span className="red">*</span>}
      </span>
      <textarea
        className={join([s.textarea, className, s["textarea__with-label"]])}
        value={value}
        rows={internalRows}
        ref={ref}
        style={{ ...(!resize && { resize: "none" }) }}
        required={required}
        {...props}
      />
    </label>
  ) : (
    <>
      <textarea
        className={join([s.textarea, className])}
        value={value}
        rows={internalRows}
        ref={ref}
        required={required}
        style={{ ...(!resize && { resize: "none" }) }}
        {...props}
      />
    </>
  );
};
