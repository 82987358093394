import { useRef } from "react";

import file from "../../assets/icons/File.svg";

export const Img = (
  props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
) => {
  const ref = useRef<HTMLImageElement>(null);

  return (
    <img
      ref={ref}
      alt=""
      onError={() => {
        if (ref.current) {
          ref.current.src = file;
          ref.current.style.objectFit = "none";
        }
      }}
      {...props}
    />
  );
};
