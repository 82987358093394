import { ISubmission } from "./../../types/submission.types";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { api } from "../../api/api";

interface IRequest {
  firstname: string;
  lastname: string;
  email: string;
  submissionId: string;
}

const fetcher = async ({ submissionId, ...body }: IRequest) => {
  const res = await api.post({ url: `/submissions/${submissionId}/validate`, body });
  return res;
};

export const useSubmissionValidate = <TData = ISubmission, TError = unknown>(
  options?: UseMutationOptions<TData, TError, IRequest>
) => {
  return useMutation<TData, TError, IRequest>(fetcher, options);
};
