import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { api } from "../../api/api";

// types
import { IStatus } from "../../types/shared.types";

const fetcher = async (formId: string | undefined) => {
  return api.get({ url: `/forms/${formId}/workflow` });
};

export const useFormWorkflow = <TQueryFnData = IStatus[], TError = unknown, TData = IStatus[]>(
  formId: string | undefined,
  options?: UseQueryOptions<TQueryFnData, TError, TData, QueryKey>
): UseQueryResult<TData, TError> => {
  return useQuery<TQueryFnData, TError, TData>(
    ["forms", formId, "workflows"],
    () => fetcher(formId),
    {
      enabled: !!formId,
      ...options
    }
  );
};
