import { useQueryClient } from "@tanstack/react-query";
import { Navigate, useParams } from "react-router-dom";

// components
import { Alert } from "../../components/Alert/Alert";
import { Upload } from "../../components/Upload/Upload";
import { ISubmission } from "../../types/submission.types";
import { FormIFrame } from "../FormIFrame/FormIFrame";

// helpers
import { routes } from "../../routes/routes";
import { join } from "../../utils/join";

// style
import s from "./SubmissionResult.module.scss";

export const SubmissionResult = () => {
  const { processNo } = useParams();
  const queryClient = useQueryClient();
  const submission = queryClient.getQueryData<ISubmission>(["submission", processNo, "validation"]);

  const isSuccess = submission?.status === "ACCEPTED";
  const isRejected = submission?.status === "REJECTED";

  if (!submission) {
    return <Navigate to={routes.pathToSignIn()} replace />;
  }

  return (
    <div className={join([s["submission-result"], "container"])}>
      {isSuccess && (
        <Alert
          type="success"
          title="Antrag vollständig bearbeitet"
          layout="vertically"
          message="Sie können die untenstehenden Dateien nun herunterladen. Für zusätzliche Information, reichen Sie bitte einen neuen Antrag ein."
        />
      )}
      {isRejected && (
        <Alert
          type="warning"
          title="Wir konnten Ihren Antrag leider nicht bearbeiten."
          layout="vertically"
          message={
            <div>
              <p className="secondary fs-12 mg-b-8">Grund für die Ablehnung</p>
              <p>{submission.statusUpdateComment}</p>
            </div>
          }
        />
      )}
      {isSuccess && (
        <article className={s.files}>
          <h4 className="mg-b-16">Files</h4>
          <Upload listClassName="mg-0" fileList={submission?.attachments || []} />
        </article>
      )}
      <FormIFrame props={submission?.props} hideExport />
    </div>
  );
};
