import { ReactComponent as NoData } from "../../assets/icons/NoData.svg";
import { join } from "../../utils/join";

// style
import s from "./Empty.module.scss";

interface IEmpty {
  className?: string;
}

export const Empty = ({ className }: IEmpty) => {
  return (
    <div className={join([s.empty, className])}>
      <NoData />
      <span className="secondary">Keine Daten</span>
    </div>
  );
};
