import { ReactComponent as Attachment } from "../../assets/icons/Attachment.svg";

// styles
import s from "./AttachmentsCount.module.scss";

interface IAttachmentsCount {
  count: number;
}

export const AttachmentsCount = ({ count }: IAttachmentsCount) => {
  return (
    <div className={s["attachments-count"]}>
      <Attachment />
      {count}
    </div>
  );
};
