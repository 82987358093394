import { Link } from "../Link/Link";
import { ReactComponent as Logo } from "../../assets/icons/Logo.svg";

import s from "./Header.module.scss";
import { useAuth0 } from "@auth0/auth0-react";

export const Header = () => {
  const {logout} = useAuth0();

  return (
    <nav className={s.header}>
      <div className={s["logo-wrapper"]}>
        <Link to={"/"}>
          <Logo />
        </Link>
        <ul className={s.links}>
          <li>
            <Link to="/#">Formulare</Link>
          </li>
        </ul>
        <ul className={s["action-links"]}>
          <li>
            <button onClick={ () => logout() }>Logout</button>
          </li>
        </ul>
      </div>
    </nav>
  );
};
