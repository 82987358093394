import { useState } from "react";
import { useParams } from "react-router-dom";

// hooks
import { useSubmissions } from "./useSubmissions";
import { useFormWorkflow } from "../../hooks/queries/useFromWorkflow";
import { useForm } from "../../hooks/queries/useForm";

// components
import { Search } from "../../components/Search/Search";
import { SelectSortByDate, TSortType } from "../../components/SelectSortByDate/SelectSortByDate";
import { SubmissionsCard } from "./SubmissionsCard";
import { CardList } from "../../components/CardList/CardList";
import { SelectFilterByStatus } from "../../components/SelectFilterByStatus/SelectFilterByStatus";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";

// helpers
import { routes } from "../../routes/routes";

// types
import { ISubmission } from "../../types/submission.types";

// styles
import s from "./Submissions.module.scss";

export const Submissions = () => {
  const { formId } = useParams();
  const submissions = useSubmissions(formId);
  const { data: statuses } = useFormWorkflow(formId);
  const { data: form } = useForm(formId);
  const { title } = form?.options || {};

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState<TSortType>("default");
  const [status, setStatus] = useState<string>("all");

  const filterByStatus = (submission: ISubmission) => {
    if (status === "all") return true;
    return submission.status === status;
  };

  const filterBySearch = (submission: ISubmission) => {
    if (!search) return true;
    const lcSearch = search.toLocaleLowerCase()
    return submission.processNo.toLocaleLowerCase().includes(lcSearch) || 
      !!Object.values(submission.props).find(p => p.toLocaleLowerCase().includes(lcSearch));
  };

  const addStatusName = (submission: ISubmission): ISubmission & { statusName: string } => {
    return {
      ...submission,
      statusName: statuses?.find((el) => el.id === submission.status)?.name!
    };
  };

  const sortSubmission = (a: ISubmission, b: ISubmission) => {
    if (sort === "updated_at") {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    }
    if (sort === "newest") {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    }

    //newest is default
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  };

  const normalizedSubmissions = submissions.data
    ?.filter(filterBySearch)
    .filter(filterByStatus)
    .sort(sortSubmission)
    .map(addStatusName);

  return (
    <>
      <Breadcrumb
        className="container"
        items={[
          {
            path: `/${routes.pathToForms()}`,
            label: "Formulare"
          },
          {
            path: `/${routes.pathToSubmissions(formId!)}`,
            label: title!
          }
        ]}
      />

      <main className="container">
        <nav className={s["submissions--nav"]}>
          <Search value={search} onChange={(e) => setSearch(e.target.value)} />
          <div className={s["submissions--selects"]}>
            <SelectFilterByStatus formId={formId!} value={status} onSelect={setStatus} />
            <SelectSortByDate value={sort} onSelect={setSort} />
          </div>
        </nav>
        <CardList
          cards={normalizedSubmissions}
          renderCard={(el) => <SubmissionsCard key={el.processNo} submission={el} />}
        />
      </main>
    </>
  );
};
