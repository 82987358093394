import { useParams } from "react-router-dom";

import { ReactComponent as Download } from "../../assets/icons/Download.svg";

// helpers
import { join } from "../../utils/join";

// hooks
import { useForm } from "../../hooks/queries/useForm";

// types
import { IFormProps } from "../../types/shared.types";

// styles
import s from "./FormIFrame.module.scss";

interface IFormIFrame {
  className?: string;
  props?: IFormProps;
  hideExport?: boolean;
}

export const FormIFrame = ({ className, props, hideExport }: IFormIFrame) => {
  const { formId } = useParams();

  const { data: form } = useForm(formId);
  const { formUrl, height, title } = form?.options || {};

  return formUrl ? (
    <div className={join([s["form-iframe-wrapper"], className])}>
      {!hideExport && (
        <button onClick={(e) => (document.getElementById('form-iframe') as HTMLIFrameElement)
          .contentWindow?.postMessage({externalFunction: 'exportData'}, "*")} className={join([s.download, "fs-12"])}>
          <Download /> Export .csv
        </button>
      )}
      <iframe
        id="form-iframe"
        className={s["form-iframe"]}
        title={title}
        height={height}
        width="100%"
        onLoad={(e) => {
          (e.target as HTMLIFrameElement).contentWindow?.postMessage({props}, "*");
        }}
        src={formUrl}
      />
    </div>
  ) : null;
};
