// components
import { ChangeStatus } from "../ChangeStatus/ChangeStatus";
import { UploadSubmissionDocuments } from "../UploadSubmissionDocuments/UploadSubmissionDocuments";

// helpers
import { join } from "../../../utils/join";

// types
import s from "./SubmissionGeneraInfo.module.scss";

interface ISubmissionGeneraInfo {
  className?: string;
  formTitle?: string;
  formId?: string;
  processNo?: string;
  reason?: string;
  status?: string;
}

export const SubmissionGeneraInfo = ({
  className,
  processNo,
  formTitle,
  formId,
  reason,
  status
}: ISubmissionGeneraInfo) => {
  return (
    <article className={join([s["submission-genera-info"], className])}>
      <h4 className="mg-0">Antragsergebnisse</h4>

      <div className={s["submission-genera-info--main"]}>
        <span className="fl-col">
          <span className="secondary">Formular</span>
          <span>{formTitle}</span>
        </span>
        <span className="fl-col">
          <span className="secondary">ID</span>
          <span>{processNo}</span>
        </span>
      </div>

      <div className={s["submission-genera-info--change-status"]}>
        <ChangeStatus status={status} processNo={processNo} formId={formId} formTitle={formTitle} />
        {reason && (
          <>
            <p className="secondary mg-b-6 mg-t-12">Begründung</p>
            <p className="mg-0">{reason}</p>
          </>
        )}
      </div>
      <UploadSubmissionDocuments />
    </article>
  );
};
