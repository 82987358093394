import { ISubmission } from "./../../../../types/submission.types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../../../../api/api";

interface IRequest {
  processNo: string;
  content: string;
}

const fetcher = async ({ processNo, content }: IRequest) => {
  return api.post({ url: `/submissions/${processNo}/comments`, body: { content } });
};

export const useAddComment = () => {
  const queryClient = useQueryClient();

  return useMutation<ISubmission, unknown, IRequest>(fetcher, {
    onSuccess: (data, { processNo }) => {
      queryClient.setQueryData(["submission", processNo], data);
    }
  });
};
