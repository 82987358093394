// components
import { ReactComponent as SvgFile } from "../../../assets/icons/File.svg";

// styles
import s from "./Document.module.scss";

interface IDocument {
  mimetype?: string;
}

export const Document = ({ mimetype }: IDocument) => {
  const extension = mimetype?.split("/")[1];

  return (
    <span className={s.document}>
      <SvgFile />
      {mimetype && <span className={s["document--text"]}>{extension?.toUpperCase()}</span>}
    </span>
  );
};
