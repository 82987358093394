import { Route, Routes } from "react-router-dom";

// components
import { UserSignIn } from "../containers/UserSignIn/UserSignIn";
import { SubmissionResult } from "../containers/SubmissionResult/SubmissionResult";

// helpers
import { routes } from "./routes";

import { CustomerLayout } from "../layouts/CustomerLayout/CustomerLayout";

export const CustomerRoutes = () => {
  return (
    <CustomerLayout>
      <Routes>
        <Route path={routes.SIGN_IN} element={<UserSignIn />} />
        <Route path={routes.FORM_SUBMISSION_RESULT} element={<SubmissionResult />} />
      </Routes>
    </CustomerLayout>
  );
};
