import { ReactNode } from "react";
import { ReactComponent as AttentionTriangle } from "../../assets/icons/AttentionTriangle.svg";
import { ReactComponent as Checkmark } from "../../assets/icons/Checkmark.svg";
import { ReactComponent as Warning } from "../../assets/icons/Warning.svg";

// helpers
import { join } from "../../utils/join";

// style
import s from "./Alert.module.scss";

interface IAlert {
  layout?: "vertically" | "horizontally";
  type?: "error" | "warning" | "success";
  message: ReactNode;
  title?: string;
  className?: string;
}

const getIcon = (type: IAlert["type"]) => {
  switch (type) {
    case "error":
      return AttentionTriangle;
    case "warning":
      return Warning;
    case "success":
      return Checkmark;
    default:
      return null;
  }
};

export const Alert = ({
  type = "error",
  layout = "horizontally",
  message,
  title = "Achtung!",
  className
}: IAlert) => {
  const Icon = getIcon(type);

  return (
    <div className={join([s.alert, s[`alert`], s[type], s[`layout__${layout}`], className])}>
      {Icon && <Icon className={s.icon} />}
      <div className={s["alert--content"]}>
        <h5 className={s["alert--title"]}>{title}</h5>
        <div className={s["alert--msg"]}>{message}</div>
      </div>
    </div>
  );
};
