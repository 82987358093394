import { useRef, useState } from "react";

// components
import { ReactComponent as ChevronDown } from "../../../assets/icons/ChevronDown.svg";
import { ReactComponent as RightArrowCircle } from "../../../assets/icons/RightArrowCircle.svg";
import { StatusBadge } from "../../../components/StatusBadge/StatusBadge";
import { Modal } from "../../../components/Modal/Modal";
import { Alert } from "../../../components/Alert/Alert";
import { Textarea } from "../../../components/Textarea/Textarea";

// hooks
import { useFormWorkflow } from "../../../hooks/queries/useFromWorkflow";
import { useUpdateSubmissionStatus } from "./useUpdateSubmissionStatus";

// helpers
import { join } from "../../../utils/join";
import { statuses as statusesSettings } from "../../../constants/constants";

import { IStatus } from "../../../types/shared.types";

// styles
import s from "./ChangeStatus.module.scss";

interface IChangeStatus {
  status?: string;
  processNo?: string;
  formId?: string;
  formTitle?: string;
}

export const ChangeStatus = ({ status, processNo, formId, formTitle }: IChangeStatus) => {
  const ref = useRef<any>(null);
  const [toStatus, setToStatus] = useState<IStatus | null>();

  const closeModal = () => setToStatus(null);
  const updateSubmissionStatus = useUpdateSubmissionStatus({ onSettled: closeModal });
  const { data: statuses } = useFormWorkflow(formId);
  const currStatus = statuses?.find((el) => el.id === status);

  const { color: selectedColor } = status ? statusesSettings[status] : { color: "blue" };
  const isStatusFinal = (statusToCheck?: string) =>
    statusToCheck === "ACCEPTED" || statusToCheck === "REJECTED";

  const handleSelect = (value: IStatus) => {
    ref.current.open = false;
    console.log(value);
    setToStatus(value);
  };

  const onChangeStatus = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);

    updateSubmissionStatus.mutate({
      processNo: processNo!,
      body: { newStatus: toStatus?.id!, comment: formData.get("comment") as string }
    });
  };

  return (
    <>
      <details ref={ref} className={s["change-status"]} role="list">
        <summary
          aria-haspopup="listbox"
          className={join([
            s["change-status--summary"],
            s[`change-status--summary__${selectedColor}`]
          ])}
          role="button"
          aria-disabled={isStatusFinal(currStatus?.id)}
          onClick={(e) => {
            if (isStatusFinal(currStatus?.id)) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          {currStatus?.name || currStatus?.id} <ChevronDown />
        </summary>
        <ul role="listbox" className={s["change-status--list"]}>
          <li className={[s["change-status--list-title"]].filter(Boolean).join(" ")}>
            <span className="fs-12 fw-medium">Neuen Status auswählen</span>
          </li>

          {statuses
            ?.filter((status) => status.id !== currStatus?.id)
            .map((status) => (
              <li
                className={[s["select--item"]].filter(Boolean).join(" ")}
                onClick={() => handleSelect(status)}
                key={status.name}
              >
                <StatusBadge status={status.id} label={status.name} />
              </li>
            ))}
        </ul>
      </details>

      <form onSubmit={onChangeStatus} className="mg-0">
        <Modal
          title="Status wechseln"
          open={!!toStatus}
          onClose={closeModal}
          titleCaption="Sind Sie sicher, dass die den Status ändern möchten?"
          footerClassName="mg-t-14"
        >
          <div className={s["change-status--modal-info"]}>
            <span>
              <span className="secondary">Formular</span> <span>{formTitle}</span>
            </span>
            <span>
              <span className="secondary">Identificator</span> <span>{processNo}</span>
            </span>
          </div>
          <div className={s["change-status--from-to"]}>
            {currStatus?.id && <StatusBadge status={currStatus.id} label={currStatus.name} />}
            {toStatus && (
              <>
                <RightArrowCircle />
                <StatusBadge status={toStatus.id} label={toStatus.name} />
              </>
            )}
          </div>

          
          {isStatusFinal(toStatus?.id) && (
          <>
            <Textarea
              label="Zusätzliche Informationen"
              resize={false}
              rows={3}
              id="comment"
              name="comment"
              placeholder="Bitte geben Sie weitere Information für den Antragssteller ein"
              required
            />
            <Alert
              type="error"
              className="mg-t-14"
              message="Durch eine Änderung wird der Antragsteller informiert."
            />
          </>
          )}
        </Modal>
      </form>
    </>
  );
};
